import React from "react";
import MainNavBar from "./MainNavBar";
import { graphql, StaticQuery } from "gatsby";
import style from "../styles/aboutUs.css";
import Footer from "./Footer";
import healthPDF from "../../img/uploads/emergency.pdf";
import disclosurePDF from "../../img/uploads/disclosure.pdf";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import jsonData from "./about.json"

export const query = graphql`
  query about {
    ourServicesGatsby: file(relativePath: { eq: "au-diverse.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }

    dataJson {
      about_us_title_deluxe
    }
  }
`;

function AboutUs({ data }) {
  const ourServicesGatsby = getImage(data.ourServicesGatsby);

  return (
    <div>
      <div>
        <MainNavBar />
      </div>
      <div className="about-us-cnt">
        <h1 className="about-us-title">
          {jsonData.about_us_title_deluxe}
        </h1>
      </div>

      <section className="main-about-us-box auto-height">
        <div className="main-about-us-cnt au-page-width">
          <div className="text-card au-txt-card auto-height">
            <h3 className="who-are-we">Our Mission Statement</h3>
            <p className="about-us-paragrapgh">
            Our Nursing Home emphasizes the personal needs of each family member placed in our care to enhance their quality of life and daily experiences. We will fulfill this through our Mission:<br></br>
	          <em><strong>We are committed to providing individualized, exceptional, and compassionate healthcare for the families in our community.</strong><br></br>
	          Our Residents do not live in our place, we work in their home.</em>
            </p>
          </div>
          <div className="au-health-ctn auto-height">
            <u className="au-healh-txt"><p>NYS DOH Comprehensive Emergency Management Plan</p></u>
            <a href={healthPDF}>
              <div className="pdf-ctn">
                Click Here to Download
              </div>
            </a>
          </div>
           <div className="au-health-ctn auto-height">
            <u className="au-healh-txt"><p>Disclosure</p></u>
            <a href={disclosurePDF}>
              <div className="pdf-ctn">
                Click Here to Download
              </div>
            </a>
          </div>
          <div className="au-guidlines-ctn auto-height margin-bottom">
            <u className="au-healh-txt"><p>Visitation Guidelines and Procedure</p></u>
            <p className="guidline-txt">Revised CMS regulations, we are pleased to report the following changes to our visitation policies effective November 18, 2021:</p>
            <ul className="guidline-ctn">
            <li>Visitation will no longer require scheduling of appointments</li>
              <li>Visitation is available seven days a week</li>
              <li>Suggested visitation hours are between 10:30am and 7:30pm</li>
              <li>Visitation may occur anywhere in the building as long as social distancing and other infection control practices are properly maintained</li>
              <li>In order to ensure social distancing and appropriate infection control, we reserve the right to limit the total number of visitors in the building at any given time</li>
              <li>Visitors MUST always wear a surgical mask when moving throughout the facility, whenever six feet social distancing cannot be properly maintained, and at all times when visiting in a resident’s room when a roommate is present</li>
              <li>All visitors MUST be screened at the front door prior to entry into the building</li>
              <li>Anyone with active symptoms, a positive COVID-19 test, or currently meeting the criteria for quarantine should not enter the building</li>
              <li>Any resident who is exposed to someone with confirmed or suspected COVID-19 infection will be quarantined for 14 days upon return to the facility from out on pass</li>
              <li>In the event of an outbreak or significant exposure, the above procedures may be modified</li>
              <li>For the continued safety of all our residents and staff, anyone found to be violating the above procedures or failing to provide accurate vaccination status information may be restricted from future visitation</li>
              <li>All visitors MUST be screened at the front door prior to entry into the building</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="image-about-us-box">
        <div className="image-about-us-cnt au-page-width">
          <div className="image-card">
            <GatsbyImage
              className="image-about-us-newPage"
              placeholder="blurred"
              image={ourServicesGatsby}
            />
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default AboutUs;
